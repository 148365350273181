<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout wrap v-if="usuario.tipo === 'administrador'">
      <v-flex md12 sm12 lg4>
        <material-chart-card
          :data="dailySalesChart.data"
          :options="dailySalesChart.options"
          color="#baa995"
          type="Line"
          v-if="usuario.tipo === 'administrador'"
        >
          <h4 class="title font-weight-light">Vendas na Semana</h4>
          <p class="category d-inline-flex font-weight-light">
            <!-- <v-icon color="green" small>mdi-arrow-up</v-icon> -->
            <!-- <span class="green--text">55%</span>&nbsp; -->
            Quantidade de vendas por dia
          </p>

          <!-- <template slot="actions">
            <v-icon
              class="mr-2"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">updated 4 minutes ago</span>
          </template>-->
        </material-chart-card>
      </v-flex>
      <v-flex md12 sm12 lg4>
        <material-chart-card
          :data="emailsSubscriptionChart.data"
          :options="emailsSubscriptionChart.options"
          :responsive-options="emailsSubscriptionChart.responsiveOptions"
          color="#baa995"
          type="Bar"
          v-if="usuario.tipo === 'administrador'"
        >
          <h4 class="title font-weight-light">Quantidade de Vendas</h4>
          <p class="category d-inline-flex font-weight-light"></p>
          <!-- 
          <template slot="actions">
            <v-icon
              class="mr-2"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">updated {} minutes ago</span>
          </template>-->
        </material-chart-card>
      </v-flex>
      <v-divider></v-divider>
      <v-flex md12 sm12 lg4>
        <!-- <material-chart-card
          :data="dataCompletedTasksChart.data"
          :options="dataCompletedTasksChart.options"
          color="green"
          v-if="usuario.tipo === 'administrador'"
          type="Line"
        >
          <h3 class="title font-weight-light">Vendas por Horário</h3>
          <p class="category d-inline-flex font-weight-light">Média de vendas por horário</p>

          <template slot="actions">
            <v-icon
              class="mr-2"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">campaign sent 26 minutes ago</span>
          </template>
        </material-chart-card> -->
      </v-flex>
      <v-flex sm6 xs12 md6 lg4>
        <material-stats-card
          color="#baa995"
          icon="mdi-store"
          title="Vendas no dia"
          :value="this.totalVendasAnual"
        />
      </v-flex>
      <v-flex sm6 xs12 md6 lg4>
        <material-stats-card
          color="#baa995"
          icon="mdi-content-copy"
          title="Qnt. de Produtos"
          :value="this.qnt.qntEstoque.toString()"
          small-value="uni."
          sub-icon-color="mdi-"
          sub-text-color="text-primary"
        />
      </v-flex>
      <v-flex sm6 xs12 md6 lg3>
        <!-- <material-stats-card
          color="red"
          icon="mdi-information-outline"
          title="Fixed Issues"
          value="75"
          sub-icon="mdi-tag"
          sub-text="Tracked from Github"
        /> -->
      </v-flex>
      <v-flex sm6 xs12 md6 lg2>
        <!-- <material-stats-card
          color="info"
          icon="mdi-twitter"
          title="Followers"
          value="+245"
          sub-icon="mdi-update"
          sub-text="Just Updated"
        /> -->
      </v-flex>
      <v-flex md12 lg8>
        <material-card
          color="#baa995"
          title="Vendas em Aberto"
          text="Vendas que faltam organizar"
        >
          <v-data-table :headers="headers" :items="vendas" hide-actions>
            <template slot="headerCell" slot-scope="{ header }">
              <span
                class="font-weight-light text-warning text--darken-3"
                v-text="header.text"
              />
            </template>
            <template slot="items" slot-scope="{ index, item }">
              <td>{{ item.codigoVenda }}</td>
              <td class="text-xs-center">
                R$ {{ item.precoPago.toString().replace(".", ",") }}
              </td>
              <td class="text-xs-center">{{ item.local }}</td>
              <td class="text-xs-center">{{ item.data }}</td>
              <td class="text-xs-center">{{ item.formaPagamento }}</td>
              <td class="text-xs-center">{{ item.status }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
      <!--
      <v-flex
        md12
        lg6
      >
        <material-card
          class="card-tabs"
          color="green">
          <v-flex
            slot="header"
          >
            <v-tabs
              v-model="tabs"
              color="transparent"
              slider-color="white"
            >
              <span
                class="subheading font-weight-light mr-3"
                style="align-self: center"
              >Tasks:</span>
              <v-tab class="mr-3">
                <v-icon class="mr-2">mdi-bug</v-icon>
                Bugs
              </v-tab>
              <v-tab class="mr-3">
                <v-icon class="mr-2">mdi-code-tags</v-icon>
                Website
              </v-tab>
              <v-tab>
                <v-icon class="mr-2">mdi-cloud</v-icon>
                Server
              </v-tab>
            </v-tabs>
          </v-flex>

          <v-tabs-items v-model="tabs">
            <v-tab-item
              v-for="n in 3"
              :key="n"
            >
              <v-list three-line>
                <v-list-tile @click="complete(0)">
                  <v-list-tile-action>
                    <v-checkbox
                      :value="list[0]"
                      color="green"
                    />
                  </v-list-tile-action>
                  <v-list-tile-title>
                    Sign contract for "What are conference organized afraid of?"
                  </v-list-tile-title>
                  <div class="d-flex">
                    <v-tooltip
                      top
                      content-class="top">
                      <v-btn
                        slot="activator"
                        class="v-btn--simple"
                        color="success"
                        icon
                      >
                        <v-icon color="primary">mdi-pencil</v-icon>
                      </v-btn>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip
                      top
                      content-class="top">
                      <v-btn
                        slot="activator"
                        class="v-btn--simple"
                        color="danger"
                        icon
                      >
                        <v-icon color="error">mdi-close</v-icon>
                      </v-btn>
                      <span>Close</span>
                    </v-tooltip>

                  </div>
                </v-list-tile>
                <v-divider/>
                <v-list-tile @click="complete(1)">
                  <v-list-tile-action>
                    <v-checkbox
                      :value="list[1]"
                      color="success"
                    />
                  </v-list-tile-action>
                  <v-list-tile-title>
                    Lines From Great Russian Literature? Or E-mails From My Boss?
                  </v-list-tile-title>
                  <div class="d-flex">
                    <v-tooltip
                      top
                      content-class="top">
                      <v-btn
                        slot="activator"
                        class="v-btn--simple"
                        color="success"
                        icon
                      >
                        <v-icon color="primary">mdi-pencil</v-icon>
                      </v-btn>
                      <span>Edit</span>
                    </v-tooltip>

                    <v-tooltip
                      top
                      content-class="top">
                      <v-btn
                        slot="activator"
                        class="v-btn--simple"
                        color="danger"
                        icon>
                        <v-icon color="error">mdi-close</v-icon>
                      </v-btn>
                      <span>Close</span>
                    </v-tooltip>
                  </div>
                </v-list-tile>
                <v-divider/>
                <v-list-tile @click="complete(2)">
                  <v-list-tile-action>
                    <v-checkbox
                      :value="list[2]"
                      color="success"
                    />
                  </v-list-tile-action>
                  <v-list-tile-title>
                    Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit
                  </v-list-tile-title>
                  <div class="d-flex">
                    <v-tooltip
                      top
                      content-class="top">
                      <v-btn
                        slot="activator"
                        class="v-btn--simple"
                        color="success"
                        icon
                      >
                        <v-icon color="primary">mdi-pencil</v-icon>
                      </v-btn>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip
                      top
                      content-class="top">
                      <v-btn
                        slot="activator"
                        class="v-btn--simple"
                        color="danger"
                        icon
                      >
                        <v-icon color="error">mdi-close</v-icon>
                      </v-btn>
                      <span>Close</span>
                    </v-tooltip>

                  </div>
                </v-list-tile>
              </v-list>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-flex>-->
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      usuario: {
        email: "",
        tipo: "administrador",
      },
      vendas: [],
      totalVendasAnual: "0",
      qnt: {
        qntTotal: 0,
        qntEstoque: 0,
      },

      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      emailsSubscriptionChart: {
        data: {
          labels: [
            "Ja",
            "Fe",
            "Ma",
            "Ap",
            "Mai",
            "Ju",
            "Jul",
            "Au",
            "Se",
            "Oc",
            "No",
            "De",
          ],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 23000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      headers: [
        {
          sortable: false,
          text: "Código",
          value: "codigoVenda",
        },
        {
          sortable: false,
          text: "Preço Pago",
          value: "precopago",
          align: "center",
        },
        {
          sortable: false,
          text: "Local",
          value: "local",
          align: "center",
        },
        {
          sortable: false,
          text: "Data",
          value: "createAt",
          align: "center",
        },
        {
          sortable: false,
          text: "Forma de Pagamento",
          value: "formaPagamento",
          align: "center",
        },
        {
          sortable: false,
          text: "Status",
          value: "status",
          align: "center",
        },
      ],
      items: [
        {
          name: "Dakota Rice",
          country: "Niger",
          city: "Oud-Tunrhout",
          salary: "$35,738",
        },
        {
          name: "Minerva Hooper",
          country: "Curaçao",
          city: "Sinaai-Waas",
          salary: "$23,738",
        },
        {
          name: "Sage Rodriguez",
          country: "Netherlands",
          city: "Overland Park",
          salary: "$56,142",
        },
        {
          name: "Philip Chanley",
          country: "Korea, South",
          city: "Gloucester",
          salary: "$38,735",
        },
        {
          name: "Doris Greene",
          country: "Malawi",
          city: "Feldkirchen in Kārnten",
          salary: "$63,542",
        },
      ],
      tabs: 0,
      list: {
        0: false,
        1: false,
        2: false,
      },
    };
  },
  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
    async load() {
      // console.log("vendas");

      await this.$http
        .get("/vendas/abertas")
        .then((res) => {
          // console.log("vendas");

          let vendas_orde = res.data;
          // console.log(res.dara);

          // console.log(vendas_orde);

          this.vendas = vendas_orde;
          // console.log("CONCLUIU");
        })
        .catch((err) => {
          if (err.error && err.error != 'Token invalid') alert(err.error);
        });
    },
    async getAnalysesData() {
      this.$http
        .get("/produtos/data/vendasnodia")
        .then((res) => {
          // console.log(res);
          this.totalVendasAnual =
            "R$ " +
            parseFloat(res.data.valorDia)
              .toFixed(2)
              .toString()
              .replace(".", ",");
        })
        .catch((err) => {
          if (err.error && err.error != 'Token invalid') alert(err.error);
          
        });

      this.$http
        .get("/vendas/weeksales")
        .then((res) => {
          this.dailySalesChart.data.series = [res.data];
          let maior = 0;
          for (let e of res.data) {
            if (e > maior) {
              maior = e;
            }
          }

          this.dailySalesChart.options.high = maior + 10;
        })
        .catch((err) => {
          if (err.error && err.error != 'Token invalid') alert(err.error);
          
        });

      this.$http
        .get("/produtos/data/quantidade")
        .then((res) => {
          // console.log(res);
          (this.qnt.qntTotal = res.data.qntTotal),
            (this.qnt.qntEstoque = res.data.qntEstoque);
        })
        .catch((err) => this.error(err));

      this.$http
        .get("/produtos/data/vendaspormeses")
        .then((res) => {
          let mes = res.data.meses;
          this.emailsSubscriptionChart.data.series = [
            [
              parseInt(mes.jan),
              parseInt(mes.fev),
              parseInt(mes.mar),
              parseInt(mes.abr),
              parseInt(mes.mai),
              parseInt(mes.jun),
              parseInt(mes.jul),
              parseInt(mes.ago),
              parseInt(mes.set),
              parseInt(mes.out),
              parseInt(mes.nov),
              parseInt(mes.dez),
            ],
          ];

          let maior = 0;

          if (mes.jan > maior) maior = mes.jan;
          if (mes.fev > maior) maior = mes.fev;
          if (mes.mar > maior) maior = mes.mar;
          if (mes.abr > maior) maior = mes.abr;
          if (mes.mai > maior) maior = mes.mai;
          if (mes.jun > maior) maior = mes.jun;
          if (mes.jul > maior) maior = mes.jul;
          if (mes.ago > maior) maior = mes.ago;
          if (mes.set > maior) maior = mes.set;
          if (mes.out > maior) maior = mes.out;
          if (mes.nov > maior) maior = mes.nov;
          if (mes.dez > maior) maior = mes.dez;

          this.emailsSubscriptionChart.options.high = maior + 1000;
        })
        .catch((err) => {
          if (err.error && err.error != 'Token invalid') alert(err.error);
          
        }); //this.error(err))
    },
    error(err) {
      // console.log(err);
      if (err.error && err.error != 'Token invalid') alert(err.error);
      
      localStorage.setItem("user", null);
      localStorage.setItem("token", null);
      this.$router.push({ name: "Login" });
    },
  },
  async beforeMount() {
    await this.load();
    await this.getAnalysesData();
  },
};
</script>
